import http from "../utils/http"


/*
* 用户观看课程进度
* */
export function userPlay(params) {
    return http.get(`/api/users/user_play`, params);
}
// 修改/添加观看记录
export function addLookRecord(params) {
   return http.get(`/api/users/add_look_record`, params);
}
// 医知学支付
export function doPay(params) {
   return http.get(`/api/index/doPay`, params);
}
// 购买记录
export function payRecord(params) {
   return http.get(`/api/users/pay_record`, params);
}
// 观看记录列表
export function lookRecord(params) {
   return http.get(`/api/users/look_record`, params);
}
// 观看记录列表
export function messageList(params) {
   return http.get(`/api/users/message_list`, params);
}
// 轮询支付信息
export function searchOrderApi(params) {
   return http.post(`/api/users/search_order`, params);
}
